var __esDecorate = (this && this.__esDecorate) || function (ctor, descriptorIn, decorators, contextIn, initializers, extraInitializers) {
    function accept(f) { if (f !== void 0 && typeof f !== "function") throw new TypeError("Function expected"); return f; }
    var kind = contextIn.kind, key = kind === "getter" ? "get" : kind === "setter" ? "set" : "value";
    var target = !descriptorIn && ctor ? contextIn["static"] ? ctor : ctor.prototype : null;
    var descriptor = descriptorIn || (target ? Object.getOwnPropertyDescriptor(target, contextIn.name) : {});
    var _, done = false;
    for (var i = decorators.length - 1; i >= 0; i--) {
        var context = {};
        for (var p in contextIn) context[p] = p === "access" ? {} : contextIn[p];
        for (var p in contextIn.access) context.access[p] = contextIn.access[p];
        context.addInitializer = function (f) { if (done) throw new TypeError("Cannot add initializers after decoration has completed"); extraInitializers.push(accept(f || null)); };
        var result = (0, decorators[i])(kind === "accessor" ? { get: descriptor.get, set: descriptor.set } : descriptor[key], context);
        if (kind === "accessor") {
            if (result === void 0) continue;
            if (result === null || typeof result !== "object") throw new TypeError("Object expected");
            if (_ = accept(result.get)) descriptor.get = _;
            if (_ = accept(result.set)) descriptor.set = _;
            if (_ = accept(result.init)) initializers.unshift(_);
        }
        else if (_ = accept(result)) {
            if (kind === "field") initializers.unshift(_);
            else descriptor[key] = _;
        }
    }
    if (target) Object.defineProperty(target, contextIn.name, descriptor);
    done = true;
};
var __runInitializers = (this && this.__runInitializers) || function (thisArg, initializers, value) {
    var useValue = arguments.length > 2;
    for (var i = 0; i < initializers.length; i++) {
        value = useValue ? initializers[i].call(thisArg, value) : initializers[i].call(thisArg);
    }
    return useValue ? value : void 0;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __setFunctionName = (this && this.__setFunctionName) || function (f, name, prefix) {
    if (typeof name === "symbol") name = name.description ? "[".concat(name.description, "]") : "";
    return Object.defineProperty(f, "name", { configurable: true, value: prefix ? "".concat(prefix, " ", name) : name });
};
import "reflect-metadata";
import { inject, singleton } from "tsyringe";
import { BaseApiClient } from "./base_api_client";
let ApiClient = (() => {
    let _classDecorators = [singleton()];
    let _classDescriptor;
    let _classExtraInitializers = [];
    let _classThis;
    let _classSuper = BaseApiClient;
    var ApiClient = _classThis = class extends _classSuper {
        constructor(env, region, httpClient, credentialProvider, lambdaClient) {
            super(ApiClient.determineApiBaseUrl(env), region, httpClient, credentialProvider, lambdaClient);
            this.env = env;
        }
        static determineApiBaseUrl(env) {
            if (process.env.REACT_APP_IS_LOCAL) {
                return "http://localhost:3500";
            }
            else if (process.env.IS_LOCAL_CONTAINER) {
                return "http://host.docker.internal:3500";
            }
            else if (this.ENV_TO_API_URLS.has(env)) {
                return this.ENV_TO_API_URLS.get(env);
            }
            else {
                console.error(`No API URL found for environment ${env}! Falling back to dev URL.`);
                return this.ENV_TO_API_URLS.get("dev");
            }
        }
        npiSearch(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("POST", "/npiSearch", request));
                }
                catch (e) {
                    this.logError("Error fetching NPI data", e);
                    throw e;
                }
            });
        }
        modelRating(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("POST", "/modelRating", request));
                }
                catch (e) {
                    this.logError("Error fetching model rating data", e);
                    throw e;
                }
            });
        }
        queueProcessDocumentRequest(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("POST", "/processDocument", request));
                }
                catch (e) {
                    this.logError("Error queuing document processing", e);
                    throw e;
                }
            });
        }
        upsertSalesforceLead(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    yield this._sendRequest(this.constructHttpRequest("POST", "/salesforceIntegration", request));
                }
                catch (e) {
                    this.logError("Error upserting Salesforce lead", e);
                    throw e;
                }
            });
        }
        processEmailAttachments(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    yield this._sendRequest(this.constructHttpRequest("POST", "/zapierIntegration", request));
                }
                catch (e) {
                    this.logError("Error processing email attachments", e);
                    throw e;
                }
            });
        }
        getSunlightAgents() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("GET", "/sunlightIntegration/agents"));
                }
                catch (e) {
                    this.logError("Error fetching Sunlight agents", e);
                    throw e;
                }
            });
        }
        previewQuoteDocument(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("POST", "/quote/previewDocument", request));
                }
                catch (e) {
                    this.logError("Error previewing quote document", e);
                    throw e;
                }
            });
        }
        sendQuoteDocument(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("POST", "/quote/afterQuote", request));
                }
                catch (e) {
                    this.logError("Error previewing quote document", e);
                    throw e;
                }
            });
        }
        calculateSunlightPremium(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("POST", "/sunlightIntegration/calculatePremium", request));
                }
                catch (e) {
                    this.logError("Error submitting Sunlight calculatePremium", e);
                    throw e;
                }
            });
        }
        invokeCalculateSunlightPremium(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const payload = {
                        resource: "/sunlightIntegration/{proxy+}",
                        path: "/sunlightIntegration/calculatePremium",
                        httpMethod: "POST",
                        headers: {
                            "content-type": "application/json",
                        },
                        pathParameters: {
                            proxy: "calculatePremium",
                        },
                        body: JSON.stringify(request),
                    };
                    return yield this._invokeLambda(`cit2-sunlight-integration-${this.env}`, payload);
                }
                catch (e) {
                    this.logError("Error invoking sunlightIntegration/calculatePremium", e);
                    throw e;
                }
            });
        }
        getSubmissionDocumentUrls(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const _req = { submissionId: request.id };
                    return yield this._sendRequest(this.constructHttpRequest("POST", "/submissionDocuments", _req));
                }
                catch (e) {
                    this.logError("Error fetching submission documents", e);
                    throw e;
                }
            });
        }
        getSubmissionDocumentPostUrls(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("POST", "/submissionDocuments", request));
                }
                catch (e) {
                    this.logError("Error fetching submission documents", e);
                    throw e;
                }
            });
        }
        /**
         * @deprecated - use appetiteRiskColor instead
         * @param request the submission to get the risk color for
         * @returns the risk color response
         */
        riskColor(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("GET", `/appetite/${request.id}/riskColor`, request));
                }
                catch (e) {
                    this.logError("Error fetching risk color", e);
                    throw e;
                }
            });
        }
        /**
         * Gets the risk color for a given submission id
         * @param submissionId the submission id to get the risk color for
         * @returns the risk color response
         */
        appetiteRiskColor(submissionId) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("GET", `/appetite/${submissionId}/riskColor`));
                }
                catch (e) {
                    this.logError("Error fetching appetite risk color", e);
                    throw e;
                }
            });
        }
        /**
         * @deprecated - use appetite instead
         * @param request the submission to get the appetite for
         * @returns the appetite response
         */
        appetiteEngine(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("GET", `/appetite/${request.id}`));
                }
                catch (e) {
                    this.logError("Error fetching appetite", e);
                    throw e;
                }
            });
        }
        /**
         * Gets the appetite for a given submission id
         * @param submissionId the submission id to get the appetite for
         * @returns the appetite response
         */
        appetite(submissionId) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("GET", `/appetite/${submissionId}`));
                }
                catch (e) {
                    this.logError("Error fetching appetite", e);
                    throw e;
                }
            });
        }
        s2q(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("POST", "/quote/automation", request));
                }
                catch (e) {
                    this.logError("Error running s2q", e);
                    throw e;
                }
            });
        }
        lambdaTester(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("POST", "/lambdaTester", request));
                }
                catch (e) {
                    this.logError("Error fetching lambdaTester", e);
                    throw e;
                }
            });
        }
        emitEvent(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("POST", "/eventEmitter", request));
                }
                catch (e) {
                    this.logError("Error emitting event", e);
                    throw e;
                }
            });
        }
        sunlightSalesforceIntegrationWebhook(event) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("POST", "/sunlightSalesforceIntegration", event));
                }
                catch (e) {
                    this.logError("Error emitting event", e);
                    throw e;
                }
            });
        }
        getRedFlagSearch(submissionId, id) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("GET", `/ai/redFlagSearch/submission/${submissionId}/id/${id}`));
                }
                catch (e) {
                    this.logError("Error fetching red flag search", e);
                    throw e;
                }
            });
        }
        postRedFlagSearch(request) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield this._sendRequest(this.constructHttpRequest("POST", `/ai/redFlagSearch`, request));
                }
                catch (e) {
                    this.logError("Error posting red flag search", e);
                    throw e;
                }
            });
        }
    };
    __setFunctionName(_classThis, "ApiClient");
    (() => {
        var _a;
        const _metadata = typeof Symbol === "function" && Symbol.metadata ? Object.create((_a = _classSuper[Symbol.metadata]) !== null && _a !== void 0 ? _a : null) : void 0;
        __esDecorate(null, _classDescriptor = { value: _classThis }, _classDecorators, { kind: "class", name: _classThis.name, metadata: _metadata }, null, _classExtraInitializers);
        ApiClient = _classThis = _classDescriptor.value;
        if (_metadata) Object.defineProperty(_classThis, Symbol.metadata, { enumerable: true, configurable: true, writable: true, value: _metadata });
    })();
    _classThis.ENV_TO_API_URLS = new Map([
        ["dev", "https://dev.api.getindigo.com"],
        ["uat", "https://uat.api.getindigo.com"],
        ["main", "https://main.api.getindigo.com"],
        ["yotam", "https://yotam.api.getindigo.com"],
        ["acorley", "https://acorley.api.getindigo.com"],
        ["ean", "https://ean.api.getindigo.com"],
    ]);
    (() => {
        __runInitializers(_classThis, _classExtraInitializers);
    })();
    return ApiClient = _classThis;
})();
export { ApiClient };
