import { useState } from "react";
import { LuDot } from "react-icons/lu";
import { Button, Text, Flex, Box, IconButton, useDisclosure } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

type Individual = {
  name: string;
  id: string;
  hasError: boolean;
};

export interface RosterSummaryProps {
  scrollTo: (id: string) => void;
  providers: Individual[];
  midlevels: Individual[];
  entities: Individual[];
  quotePropertiesErrorCount?: number;
  activeSection: string | null;
}

const ErrorCount = ({ count }: { count: number }) => (
  <div
    style={{
      marginTop: "5px",
      paddingTop: "1px",
      fontSize: "0.85rem",
      height: "20px",
      width: "20px",
      borderRadius: "100%",
      background: "#EC5B29",
      color: "white",
    }}
  >
    {count}
  </div>
);

export function RosterSummary({
  quotePropertiesErrorCount = 0,
  scrollTo,
  providers,
  midlevels,
  entities,
  activeSection,
}: RosterSummaryProps) {
  const { isOpen, onToggle } = useDisclosure();
  const [selectedSubState, setSelectedSubState] = useState<string | null>(null);
  const providerErrorCount = providers.filter((provider) => provider.hasError).length;
  const midlevelErrorCount = midlevels.filter((midlevel) => midlevel.hasError).length;
  const entityErrorCount = entities.filter((entity) => entity.hasError).length;

  return (
    <Box mx="10px" position="sticky" top="100px" left="0" mt="35px">
      <Flex>
        <IconButton
          zIndex="11"
          borderColor="gray.200"
          borderWidth="1px"
          backgroundColor="white"
          aria-label="Open roster summary"
          onClick={onToggle}
          fontSize="xl"
          borderRadius="3px"
          margin="0"
          h="32px"
          size="sm"
          color="indigo.400"
          icon={isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        />
        <Flex
          direction="column"
          textAlign="left"
          mt="-4px"
          style={{
            width: isOpen ? "216px" : "0",
            transition: "all 0.2s ease-in-out",
            overflow: "hidden",
          }}
        >
          <Button
            px="6px"
            py="0px"
            size="sm"
            fontWeight={activeSection === "quote-summary" ? "bold" : "semibold"}
            backgroundColor={activeSection === "quote-summary" ? "gray.200" : "transparent"}
            justifyContent="left"
            onClick={() => {
              scrollTo("quote-summary");
            }}
            color="indigo.500"
          >
            <Flex w="100%" justifyContent="space-between">
              <Text>Quote Summary</Text>
              {quotePropertiesErrorCount > 0 ? (
                <ErrorCount count={quotePropertiesErrorCount} />
              ) : undefined}
            </Flex>
          </Button>
          {entities.length > 0 && (
            <Flex direction="column">
              <Button
                p="0 6px 2px 6px"
                size="sm"
                color="indigo.500"
                backgroundColor={activeSection === "entities" ? "gray.200" : "transparent"}
                fontWeight={activeSection === "entities" ? "bold" : "semibold"}
                justifyContent="left"
                onClick={() => {
                  scrollTo("entities");
                }}
              >
                <Flex w="100%" justifyContent="space-between">
                  <Text>Medical Practice</Text>
                  {entityErrorCount > 0 ? <ErrorCount count={entityErrorCount} /> : undefined}
                </Flex>
              </Button>
              <Flex
                style={{
                  maxHeight: activeSection === "entities" ? "55vh" : "0",
                  transition: "all 0.3s ease-in-out",
                  overflow: "scroll",
                }}
                direction="column"
                ml="20px"
                overflowY="scroll"
              >
                {entities?.map((entity) => (
                  <Button
                    borderRadius="0"
                    margin="0"
                    py="4px"
                    color="indigo.500"
                    backgroundColor="transparent"
                    rightIcon={
                      entity.hasError ? (
                        <LuDot style={{ marginTop: "5px", color: "red" }} />
                      ) : undefined
                    }
                    fontWeight={selectedSubState === entity.id ? "bold" : "semibold"}
                    key={entity.id}
                    justifyContent="left"
                    onClick={() => {
                      scrollTo(entity.id);
                      setSelectedSubState(entity.id);
                    }}
                  >
                    <Text key={entity.id}>{entity.name}</Text>
                  </Button>
                ))}
              </Flex>
            </Flex>
          )}
          {providers.length > 0 && (
            <Flex direction="column">
              <Button
                backgroundColor={activeSection === "providers" ? "gray.200" : "transparent"}
                p="0 6px 2px 6px"
                size="sm"
                color="indigo.500"
                fontWeight={activeSection === "providers" ? "bold" : "semibold"}
                justifyContent="left"
                onClick={() => {
                  scrollTo("providers");
                }}
              >
                <Flex w="100%" justifyContent="space-between">
                  <Text>Individual Providers</Text>
                  {providerErrorCount > 0 ? <ErrorCount count={providerErrorCount} /> : undefined}
                </Flex>
              </Button>
              <Flex
                style={{
                  maxHeight: activeSection === "providers" ? "55vh" : "0",
                  transition: "all 0.3s ease-in-out",
                  overflow: "scroll",
                }}
                direction="column"
                ml="20px"
                overflowY="scroll"
              >
                {providers?.map((provider) => (
                  <Button
                    borderRadius="0"
                    margin="0"
                    py="4px"
                    color="indigo.500"
                    backgroundColor="transparent"
                    rightIcon={
                      provider.hasError ? (
                        <LuDot style={{ marginTop: "5px", color: "red" }} />
                      ) : undefined
                    }
                    fontWeight={selectedSubState === provider.id ? "bold" : "semibold"}
                    key={provider.id}
                    justifyContent="left"
                    onClick={() => {
                      scrollTo(provider.id);
                      setSelectedSubState(provider.id);
                    }}
                  >
                    <Text key={provider.id}>{provider.name}</Text>
                  </Button>
                ))}
              </Flex>
            </Flex>
          )}
          {midlevels.length > 0 && (
            <Flex direction="column">
              <Button
                p="0 6px 2px 6px"
                size="sm"
                color="indigo.500"
                fontWeight={activeSection === "midlevels" ? "bold" : "semibold"}
                backgroundColor={activeSection === "midlevels" ? "gray.200" : "transparent"}
                justifyContent="left"
                onClick={() => {
                  scrollTo("midlevels");
                }}
              >
                <Flex w="100%" justifyContent="space-between">
                  <Text>Non Physician Providers</Text>
                  {midlevelErrorCount > 0 ? <ErrorCount count={midlevelErrorCount} /> : undefined}
                </Flex>
              </Button>
              <Flex
                style={{
                  maxHeight: activeSection === "midlevels" ? "55vh" : "0",
                  transition: "all 0.3s ease-in-out",
                  overflow: "scroll",
                }}
                direction="column"
                ml="20px"
                overflowY="scroll"
              >
                {midlevels?.map((midlevel) => (
                  <Button
                    borderRadius="0"
                    margin="0"
                    py="4px"
                    color="indigo.500"
                    fontWeight={selectedSubState === midlevel.id ? "bold" : "semibold"}
                    key={midlevel.id}
                    backgroundColor="transparent"
                    justifyContent="left"
                    rightIcon={
                      midlevel.hasError ? (
                        <LuDot style={{ marginTop: "5px", color: "red" }} />
                      ) : undefined
                    }
                    onClick={() => {
                      scrollTo(midlevel.id);
                      setSelectedSubState(midlevel.id);
                    }}
                  >
                    <Text key={midlevel.id}>{midlevel.name}</Text>
                  </Button>
                ))}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
