import { useEffect } from "react";
import { Outlet } from "react-router";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useLocalAuthSession } from "hooks";
import { UserGroups } from "localTypes";

interface ProtectedRouteProps {
  adminOnly?: boolean;
}

export function ProtectedRoute({ adminOnly }: ProtectedRouteProps) {
  const currentUser = useLocalAuthSession();
  const { signOut } = useAuthenticator((context) => [context.user]);
  const isAdmin =
    !!currentUser?.userGroups?.includes(UserGroups.Underwriters) ||
    !!currentUser?.userGroups?.includes(UserGroups.Sales);
  const isAllowed = !!currentUser?.userGroups?.includes(UserGroups.Brokers) || isAdmin;

  const showRoute = adminOnly ? isAdmin : isAllowed;

  useEffect(() => {
    if (!showRoute) {
      signOut();
    }
  }, [signOut, showRoute]);
  return <Outlet />;
}
