import { ReactNode } from "react";
import { Flex, Box } from "@chakra-ui/react";

export interface PolicyDetailProps {
  value: ReactNode;
  icon: ReactNode;
  label: ReactNode;
}

export function PolicyDetail({ label, value, icon }: PolicyDetailProps) {
  return (
    <Flex
      overflowX="scroll"
      gap="6px"
      fontSize="xm"
      color="gray.600"
      w="100%"
      justifyContent="center"
    >
      <Box mt="6px">{icon}</Box>
      <Flex grow="1" direction="column">
        {label}
        {value}
      </Flex>
    </Flex>
  );
}
