export class IndigoError extends Error {
  statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
}

export class ValidationError extends IndigoError {
  constructor(message: string) {
    super(message, 400);
    this.name = "ValidationError";
  }
}

export class InternalServerError extends IndigoError {
  constructor(message: string) {
    super(message, 500);
    this.name = "InternalServerError";
  }
}

export class InvalidConfigurationError extends IndigoError {
  constructor(message: string) {
    super(message, 400);
    this.name = "InvalidConfigurationError";
  }
}

export class DependencyError extends IndigoError {
  constructor(message: string) {
    super(message, 500);
    this.name = "DependencyError";
  }
}

export class ThrottlingError extends IndigoError {
  constructor(message: string) {
    super(message, 429);
    this.name = "ThrottlingError";
  }
}

export class InitializationError extends IndigoError {
  constructor(message: string) {
    super(message, 500);
    this.name = "InitializationError";
  }
}

export class InvalidInputError extends IndigoError {
  constructor(message: string) {
    super(message, 400);
    this.name = "InvalidInputError";
  }
}

export class NotFoundError extends IndigoError {
  constructor(message: string) {
    super(message, 404);
    this.name = "NotFoundError";
  } 
}

export class TransactionConflictError extends IndigoError {
  constructor(message: string) {
    super(message, 409);
    this.name = "TransactionConflictError";
  }
}

export class LambdaInvokeError extends IndigoError {
  constructor(message: string) {
    super(message, 500);
    this.name = "LambdaInvokeError";
  }
}
