import { useState } from "react";
import { Box, Grid, GridItem, IconButton, Text, Flex } from "@chakra-ui/react";
import { SortIcon } from "assets";
import { ChevronRightIcon, ChevronDownIcon } from "@chakra-ui/icons";

export interface TableProps {
  data: any[];
  orderedBy?: [string, "asc" | "desc"];
  onSort: (column?: string, direction?: "asc" | "desc") => void;
  onSortChildren?: (column?: string, direction?: "asc" | "desc") => void;
  isChild?: boolean;
  evenColumns?: boolean;
  showSort?: boolean;
  growColumn?: string;
}

export function Table({
  showSort = true,
  evenColumns,
  isChild,
  onSort,
  onSortChildren,
  orderedBy,
  data,
}: TableProps) {
  let columns: string[] = [];
  if (data && data.length > 0) {
    columns = Object?.keys(data?.[0])?.filter((c) => c !== "data" && c !== "id");
  }
  const [showRow, setShowRow] = useState<number | undefined>(undefined);

  return (
    <Box width="100%" overflowX="auto">
      <Grid
        templateColumns="repeat(24, 1fr)"
        bg="gray.50"
        color="gray.600"
        py="7px"
        minWidth="900px"
      >
        {!isChild && <GridItem colSpan={1} />}
        {columns?.map((column, index) => {
          return (
            <GridItem
              ml={index === 0 && isChild ? "10px" : "0px"}
              colSpan={
                evenColumns
                  ? 24 / columns.length - (index === columns.length - 1 ? 1 : 0)
                  : index === 0
                    ? 7
                    : 4
              }
              key={index}
            >
              <Flex>
                <Text textTransform="capitalize" whiteSpace="nowrap">
                  {column}
                </Text>
                {showSort && (
                  <IconButton
                    h="20px"
                    aria-label="sort"
                    variant="ghost"
                    onClick={() => {
                      setShowRow(undefined);
                      if (!orderedBy) {
                        onSort(column, "asc");
                        return;
                      }
                      if (orderedBy[0] === column) {
                        if (orderedBy[1] === "asc") {
                          onSort(column, "desc");
                          return;
                        }
                        if (orderedBy[1] === "desc") {
                          // Reset sort state if current state is descending
                          onSort();
                          return;
                        }
                      }
                      if (orderedBy[0] !== column) {
                        onSort(column, "asc");
                        return;
                      }
                    }}
                    icon={
                      <SortIcon
                        color={orderedBy?.[0] === column ? "#2C0B9B" : "#4A5568"}
                        direction={orderedBy?.[0] === column ? orderedBy?.[1] : undefined}
                      />
                    }
                    _hover={{ backgroundColor: "transparent", color: "#2C0B9B" }}
                  />
                )}
              </Flex>
            </GridItem>
          );
        })}
      </Grid>
      {data.map((row, index) => {
        let showCollapse = false;
        if (row.data && row.data.length > 0) {
          showCollapse = true;
        }
        return (
          <Flex key={index} direction="column" bg="white">
            <Grid
              borderTop="1px solid #DEDEDE"
              templateColumns="repeat(24, 1fr)"
              py="7px"
              minW={isChild ? "100%" : "900px"}
            >
              {!isChild && (
                <GridItem colSpan={1}>
                  {showCollapse && (
                    <IconButton
                      h="10px"
                      m="0"
                      mt="-5px"
                      aria-label="view-data"
                      variant="ghost"
                      onClick={() => {
                        setShowRow(showRow === index ? undefined : index);
                      }}
                      _hover={{ backgroundColor: "transparent", color: "#2C0B9B" }}
                      icon={showRow === index ? <ChevronDownIcon /> : <ChevronRightIcon />}
                    />
                  )}
                </GridItem>
              )}
              {columns.map((column, index) => {
                return (
                  <GridItem
                    colSpan={
                      evenColumns
                        ? 24 / columns.length - (index === columns.length - 1 ? 1 : 0)
                        : index === 0
                          ? 7
                          : 4
                    }
                    key={index}
                    flex={1}
                    justifyContent="start"
                    alignItems="center"
                    ml={index === 0 && isChild ? "10px" : "0px"}
                  >
                    {row[column]}
                  </GridItem>
                );
              })}
            </Grid>
            {showRow === index && row?.data && (
              <Box ml="40px" p="0px">
                <Table
                  isChild={true}
                  data={row.data}
                  onSort={onSortChildren || onSort}
                  orderedBy={orderedBy}
                />
              </Box>
            )}
          </Flex>
        );
      })}
    </Box>
  );
}
