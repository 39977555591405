import { Button, Text, Flex } from "@chakra-ui/react";
// import { HiBuildingOffice } from "react-icons/hi2";
import { Link, useParams } from "react-router-dom";
import { RiExpandRightLine, RiExpandLeftLine } from "react-icons/ri";
import { PolicyIcon } from "assets";
import "./side-nav.css";

export interface SideNavProps {
  isBig: boolean;
  setIsBig: (isBig: boolean) => void;
}

export function SideNav({ setIsBig, isBig }: SideNavProps) {
  const { orgId } = useParams();
  return (
    <Flex
      direction="column"
      gap="20px"
      mt="60px"
      justifyContent="space-between"
      h="100%"
      bgColor="#5851b8"
      color="white"
      fontSize="1.125rem"
      fontWeight={700}
      p="16px 8px 65px 8px"
    >
      <Flex direction="column" gap="20px">
        <Button
          as={Link}
          to={`/portal/${orgId}/policies`}
          _hover={{ color: "#fbd38d", bg: "transparent" }}
          leftIcon={<PolicyIcon />}
          p="8px 4px"
          variant="ghost"
          color="white"
          fontSize="md"
          fontWeight="bold"
          justifyContent="flext-start"
          alignItems="center"
        >
          {isBig && (
            <Text className="sidenav-text-fade-in" mt="-3px">
              Policies
            </Text>
          )}
        </Button>
        {/* <Button */}
        {/*   _hover={{ color: "#fbd38d", bg: "transparent" }} */}
        {/*   leftIcon={<SubmissionIcon />} */}
        {/*   p="8px 4px" */}
        {/*   variant="ghost" */}
        {/*   color="white" */}
        {/*   fontSize="md" */}
        {/*   fontWeight="bold" */}
        {/*   justifyContent="flext-start" */}
        {/* > */}
        {/*   {isBig && ( */}
        {/*     <Text className="sidenav-text-fade-in" mt="-3px"> */}
        {/*       Submissions */}
        {/*     </Text> */}
        {/*   )} */}
        {/* </Button> */}
        {/* <Button */}
        {/*   _hover={{ color: "#fbd38d", bg: "transparent" }} */}
        {/*   leftIcon={<ResourcesIcon />} */}
        {/*   p="8px 4px" */}
        {/*   variant="ghost" */}
        {/*   color="white" */}
        {/*   fontSize="md" */}
        {/*   fontWeight="bold" */}
        {/*   justifyContent="flext-start" */}
        {/* > */}
        {/*   {isBig && ( */}
        {/*     <Text className="sidenav-text-fade-in" mt="-3px"> */}
        {/*       Resources */}
        {/*     </Text> */}
        {/*   )} */}
        {/* </Button> */}
      </Flex>
      <Button
        _hover={{ color: "#fbd38d", bg: "transparent" }}
        leftIcon={isBig ? <RiExpandLeftLine /> : <RiExpandRightLine />}
        p="8px 4px"
        variant="ghost"
        color="white"
        fontSize="md"
        fontWeight="bold"
        justifyContent="flext-start"
        onClick={() => setIsBig(!isBig)}
      >
        {isBig && (
          <Text className="sidenav-text-fade-in" mt="-3px">
            Collapse
          </Text>
        )}
      </Button>
    </Flex>
  );
}
