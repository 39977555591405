import { useState, useEffect, useCallback } from "react";
import throttle from "lodash/throttle";
export const useIdleDetection = (idleTime = 60000) => {
    const [isIdle, setIsIdle] = useState(false);
    const resetTimer = useCallback(() => {
        setIsIdle(false);
    }, []);
    useEffect(() => {
        let timeoutId;
        const startTimer = () => {
            if (timeoutId)
                clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setIsIdle(true);
            }, idleTime);
        };
        const handleActivity = () => {
            resetTimer();
            startTimer();
        };
        const throttledHandle = throttle(handleActivity, 150);
        const events = ["mousedown", "keydown", "scroll", "touchstart"];
        events.forEach((event) => {
            document.addEventListener(event, handleActivity, { passive: true });
        });
        document.addEventListener("mousemove", throttledHandle, { passive: true });
        startTimer();
        // Cleanup
        return () => {
            if (timeoutId)
                clearTimeout(timeoutId);
            events.forEach((event) => {
                document.removeEventListener(event, handleActivity);
            });
            document.removeEventListener("mousemove", throttledHandle);
            throttledHandle.cancel();
        };
    }, [idleTime, resetTimer]);
    return isIdle;
};
