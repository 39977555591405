import { gql } from "@apollo/client";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  InputGroup,
  InputLeftAddon,
  ModalFooter,
  Button,
  Text,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Flex,
  ModalCloseButton,
  Select,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useModelRatingApi } from "hooks";
import { WarningIcon } from "@chakra-ui/icons";
import { GET_SUBMISSION } from "queries";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import {
  DeclinationReason,
  DeclinationInput,
  Structural,
  PracticeProfile,
} from "__generated__/graphql";
import {
  formatDeclinationReason,
  formatStructuralReason,
  formatPracticeProfileReason,
} from "utils";
import { DEFAULT_CC_EMAIL_ADDRESSES, DEFAULT_BCC_EMAIL_ADDRESSES } from "app-constants";

export const DECLINE_SUBMISSION = gql`
  mutation DeclineSubmisison($submissionId: ID!, $declination: DeclinationInput!) {
    declineSubmission(submissionId: $submissionId, declination: $declination) {
      code
      message
      success
      declination {
        emailAddresses {
          to
          cc
          bcc
        }
        reason
        otherReason
        practiceProfile
        structural
      }
    }
  }
`;

interface DeclinationModalProps {
  onClose: () => void;
  declination?: DeclinationInput;
  submitToSunlight: () => void;
  brokerEmail?: string;
}
export function DeclinationModal({
  submitToSunlight,
  declination,
  onClose,
  brokerEmail,
}: DeclinationModalProps) {
  const { fetchModelRating } = useModelRatingApi();
  const [declineSubmission, { loading }] = useMutation(DECLINE_SUBMISSION, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const { id } = useParams();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<DeclinationInput>({
    defaultValues: {
      emailAddresses: {
        to: brokerEmail || "",
        cc: DEFAULT_CC_EMAIL_ADDRESSES,
        bcc: DEFAULT_BCC_EMAIL_ADDRESSES,
      },
      otherReason: "",
      ...declination,
    },
  });
  const reason = watch("reason");

  const onSubmit = useCallback(
    async (submission: DeclinationInput) => {
      try {
        await fetchModelRating();
        const declinationResults = await declineSubmission({
          variables: {
            submissionId: id,
            declination: submission,
          },
        });
        if (declinationResults?.data?.declineSubmission?.code !== 200) {
          throw new Error("Failed to decline submission");
        }
        submitToSunlight();
        onClose();
      } catch (e) {
        console.error(e);
      }
    },
    [submitToSunlight, onClose, declineSubmission, id, fetchModelRating],
  );
  return (
    <Modal size="xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb="4px">
          <Flex direction="column" mb="8px">
            <Text lineHeight="none" fontSize="3xl">
              Declination
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <form data-testid="declination-form" onSubmit={handleSubmit(onSubmit)}>
          <ModalBody pb="20px" pt="16px">
            <Flex direction="column" gap="8px">
              <FormControl isInvalid={!!errors?.emailAddresses?.to?.message}>
                <InputGroup>
                  <InputLeftAddon
                    w="60px"
                    fontWeight="bold"
                    backgroundColor="white"
                    borderColor="indigo.300"
                    borderRight="none"
                  >
                    <FormLabel fontWeight="bold" htmlFor={`emailAddresses.to`}>
                      To
                    </FormLabel>
                  </InputLeftAddon>

                  <Input
                    data-testid="emailAddresses.to"
                    borderLeft="none"
                    id="emailAddresses.to"
                    type="text"
                    {...register("emailAddresses.to", {
                      required: "'To' is required",
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>
                  <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                  {errors?.emailAddresses?.to?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftAddon
                    w="60px"
                    fontWeight="bold"
                    backgroundColor="white"
                    borderColor="indigo.300"
                    borderRight="none"
                  >
                    <FormLabel fontWeight="bold" htmlFor={`emailAddresses.cc`}>
                      Cc
                    </FormLabel>
                  </InputLeftAddon>
                  <Input
                    borderLeft="none"
                    id="emailAddresses.cc"
                    type="text"
                    {...register("emailAddresses.cc")}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftAddon
                    w="60px"
                    fontWeight="bold"
                    backgroundColor="white"
                    borderColor="indigo.300"
                    borderRight="none"
                  >
                    <FormLabel fontWeight="bold" htmlFor={`emailAddresses.bcc`}>
                      Bcc
                    </FormLabel>
                  </InputLeftAddon>
                  <Input
                    borderLeft="none"
                    id="emailAddresses.bcc"
                    type="text"
                    {...register("emailAddresses.bcc")}
                  />
                </InputGroup>
              </FormControl>
              <FormControl isInvalid={!!errors?.reason?.message}>
                <FormLabel fontWeight="bold" htmlFor="reason" fontSize="md" mb="0px">
                  Declination Reason
                </FormLabel>
                <Select
                  data-testid="reason"
                  id="reason"
                  size="md"
                  m="0px"
                  p="0px"
                  {...register("reason", { required: "'Declination Reason' is required" })}
                  onChange={(e) => {
                    setValue("otherReason", "");
                    register("reason").onChange(e);
                  }}
                >
                  <option></option>
                  {Object.values(DeclinationReason).map((reason) => {
                    return (
                      <option key={reason} value={reason}>
                        {formatDeclinationReason(reason)}
                      </option>
                    );
                  })}
                </Select>
                <FormErrorMessage>
                  <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                  {errors?.reason?.message}
                </FormErrorMessage>
              </FormControl>
              {reason === DeclinationReason.PracticeProfile && (
                <FormControl isInvalid={!!errors?.practiceProfile?.message}>
                  <FormLabel fontWeight="bold" htmlFor="practiceProfile" fontSize="md" mb="0px">
                    Practice Profile Reason
                  </FormLabel>
                  <Select
                    data-testid="practiceProfile"
                    id="practiceProfile"
                    size="md"
                    m="0px"
                    p="0px"
                    {...register("practiceProfile", {
                      required: "'Practice Profile Reason' is required",
                    })}
                    onChange={(e) => {
                      setValue("otherReason", `${reason} - ${e.target.value}`);
                      setValue("structural", null);
                      register("practiceProfile").onChange(e);
                    }}
                  >
                    <option></option>
                    {Object.values(PracticeProfile).map((reason) => {
                      return (
                        <option key={reason} value={reason}>
                          {formatPracticeProfileReason(reason)}
                        </option>
                      );
                    })}
                  </Select>
                  <FormErrorMessage>
                    <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                    {errors?.practiceProfile?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
              {reason === DeclinationReason.Structural && (
                <FormControl isInvalid={!!errors?.structural?.message}>
                  <FormLabel fontWeight="bold" htmlFor="structural" fontSize="md" mb="0px">
                    Structural Reason
                  </FormLabel>
                  <Select
                    data-testid="structural"
                    id="structural"
                    size="md"
                    m="0px"
                    p="0px"
                    {...register("structural", { required: "'Structural Reason' is required" })}
                    onChange={(e) => {
                      setValue("otherReason", `${reason} - ${e.target.value}`);
                      setValue("practiceProfile", null);
                      register("structural").onChange(e);
                    }}
                  >
                    <option></option>
                    {Object.values(Structural).map((reason) => {
                      return (
                        <option key={reason} value={reason}>
                          {formatStructuralReason(reason)}
                        </option>
                      );
                    })}
                  </Select>
                  <FormErrorMessage>
                    <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                    {errors?.structural?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter bgColor="gray.100" pb="24px">
            <Button
              isLoading={loading}
              mx="0"
              type="submit"
              data-testid="submit"
              colorScheme="indigo"
              w="100%"
            >
              Send Declination
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
