import { ReactNode } from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Card,
  CardBody,
  DrawerCloseButton,
  Drawer,
  DrawerContent,
  DrawerBody,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";
import { RiMapPinLine, RiBallPenLine, RiCalendarLine, RiMailLine } from "react-icons/ri";
import { PolicyProvider, Underwriter } from "__generated__/graphql";
import { Loader } from "components";
import { PROVIDER_SUFFIXES } from "app-constants";
import { PolicyDetail } from "components";
import { format, fromUnixTime } from "date-fns";

interface PolicyProviderDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  provider?: PolicyProvider;
  underwriter?: Underwriter;
  documents?: ReactNode;
  loading: boolean;
  documentsTable?: ReactNode;
  error?: string;
}
export function PolicyProviderDrawer({
  loading,
  isOpen,
  onClose,
  provider,
  documentsTable,
  underwriter,
  error,
}: PolicyProviderDrawerProps) {
  return (
    <Drawer placement="right" isOpen={isOpen} onClose={onClose} size="lg">
      <DrawerContent>
        <DrawerCloseButton />
        {loading ? (
          <Loader />
        ) : (
          <DrawerBody mt="60px">
            {error || provider === undefined ? (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Something went wrong fetching the provider's details.</AlertTitle>
              </Alert>
            ) : (
              <Flex direction="column">
                <Card boxShadow="none">
                  <CardBody bgColor="gray.50">
                    <Flex alignItems="center" justifyContent="space-between" w="100%">
                      <Text fontSize="1.8rem">
                        {provider.firstName} {provider.lastName},{" "}
                        {PROVIDER_SUFFIXES[provider.suffix]}
                      </Text>
                      <Flex direction="column" textAlign="right">
                        <Text fontWeight="700">Specialty</Text>
                        <Text>{provider.specialty}</Text>
                      </Flex>
                    </Flex>
                  </CardBody>
                </Card>
                <Flex p="16px 8px" gap="16px">
                  <Flex w="100%" direction="column" gap="16px" pr="17px">
                    <Flex alignSelf="flex-start">
                      <PolicyDetail
                        icon={<RiMapPinLine />}
                        label={
                          <Text fontSize="1.125rem" fontWeight="600" color="black">
                            Practice Address
                          </Text>
                        }
                        value={
                          <>
                            <Text color="black" fontSize="1.125rem" fontWeight="500">
                              {provider.address?.streetName}, {provider.address?.secondary}
                            </Text>
                            <Text fontSize="1.125rem" fontWeight="500" color="black">
                              {provider.address?.city}, {provider.address?.state}{" "}
                              {provider.address?.zip}
                            </Text>
                          </>
                        }
                      />
                    </Flex>
                    <Flex alignSelf="flex-start">
                      <PolicyDetail
                        icon={<RiMailLine />}
                        label={
                          <Text fontSize="1.125rem" fontWeight="600" color="black">
                            Email
                          </Text>
                        }
                        value={
                          <Link
                            color="indigo.500"
                            fontWeight="500"
                            href={`mailto:${provider.email}`}
                          >
                            {provider.email}
                          </Link>
                        }
                      />
                    </Flex>
                  </Flex>
                  <Flex w="100%" direction="column" gap="16px">
                    <Flex alignSelf="flex-start">
                      <PolicyDetail
                        icon={<RiCalendarLine />}
                        label={
                          <Text fontSize="1.125rem" fontWeight="600" color="black">
                            Retroactive Date
                          </Text>
                        }
                        value={
                          <Text fontSize="1.125rem" fontWeight="500" color="black">
                            {format(fromUnixTime(provider.retroDate * 1000 || 0), "MM/dd/yyyy")}
                          </Text>
                        }
                      />
                    </Flex>
                    <Flex alignSelf="flex-start">
                      <PolicyDetail
                        icon={<RiBallPenLine />}
                        label={
                          <Text fontSize="1.125rem" fontWeight="600" color="black">
                            Underwriter
                          </Text>
                        }
                        value={
                          <Text fontSize="1.125rem" fontWeight="500" color="black">
                            {underwriter?.name}
                          </Text>
                        }
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <Flex overflowX="auto">{documentsTable}</Flex>
              </Flex>
            )}
          </DrawerBody>
        )}
      </DrawerContent>
    </Drawer>
  );
}
