import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);
const outline = definePartsStyle({
    field: {
        height: "32px",
        borderColor: "indigo.300",
        borderRadius: "2px",
        paddingLeft: "8px",
        paddingRight: "12px",
        _hover: {
            borderColor: "indigo.500",
            _disabled: {
                borderColor: "gray.50",
                color: "gray.400",
                backgroundColor: "gray.50",
                opacity: 0.7,
                cursor: "not-allowed",
            },
        },
        _focus: {
            borderColor: "indigo.500",
            boxShadow: "0 0 0 1px #2C0B9B",
        },
        _disabled: {
            borderColor: "gray.50",
            color: "gray.400",
            backgroundColor: "gray.50",
            opacity: 0.7,
            cursor: "not-allowed",
        },
    },
});
export const selectTheme = defineMultiStyleConfig({ variants: { outline } });
